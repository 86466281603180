<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add" icon="el-icon-plus">新增</el-button>
        <el-button type="danger" size="small" @click="delRow()" :disabled="!btn.delete" icon="el-icon-delete">删除</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="录入开始日期"
          end-placeholder="录入结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getTableData()"> 查询</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'usqu_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getTableData"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/DevelopManagement/UsquManage/usqu';
import { usquAPI } from '@api/modules/usqu';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'UsquList',
  components: { SearchTable },
  data() {
    return {
      dateRange: [],
      tableData: [],
      tableMap: new Map(),
      tableProperties: tableProperties,
      loadFlag: false,
      totalPage: 0,
      multiSelection: [],
      btn: {}
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    refresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    async getTableData() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime ? startTime : null;
      searchForm.end_time = endTime ? endTime : null;
      await usquAPI.get_usqus(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
      });
      await (this.loadFlag = false);
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    dbClickJp(row) {
      this.jump('/usqu_edit', {
        key: UrlEncode.encode(JSON.stringify({ perm_id: this.$route.query.perm_id, form_id: row.usqu_id }))
      });
    },
    addRow() {
      this.jump(`/usqu_add?perm_id=${this.$route.query.perm_id}`);
    },
    delRow() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let ids = Array.from(this.multiSelection, ({ usqu_id }) => usqu_id);
      usquAPI.deltet_by_uaqu_ids({ usquIdList: ids }).then(({ data }) => {
        this.$message.success('删除成功!');
      });
    }
  }
};
</script>

<style scoped></style>
